import React from 'react'
import { Script } from 'gatsby-script'
import { MailIcon, PhoneIcon, AtSymbolIcon } from '@heroicons/react/outline'

export default function Form() {
  return (
    <div className="relative bg-white">
      <div className="absolute inset-0">
        <div className="absolute inset-y-0 left-0 w-1/2 bg-gray-50" />
      </div>
      <div className="relative max-w-7xl mx-auto lg:grid lg:grid-cols-5">
        <div className="bg-gray-50 py-4 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12 hidden lg:block">
          <div className="max-w-lg mx-auto">
            <h2 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">Exemples de mise en relation</h2>
            <p className="mt-3 text-lg leading-6 text-gray-500">
              Maison : Architecte, entreprise de travaux, artisan-peintre, paysagiste, plombier, électricien, entreprise de déménagement, bureau d’étude de sol, expert bâtiment…
            </p>
            <p className="mt-3 text-lg leading-6 text-gray-500">
              Santé et bien être : coiffeur, centre de soin et bien être, psychologue, praticien en médecine douce, sophrologue, naturopathe, art thérapeute…
            </p>
            <p className="mt-3 text-lg leading-6 text-gray-500">
              Et bien d’autres métiers encore thématiques Droit, Argent et Véhicule.
            </p>
            <dl className="mt-8 text-base text-gray-500">
              <div>
                <dt className="sr-only">Adresse</dt>
                <dd>
                  <p>97 Allée Alexandre Borodine
                    Bâtiment Woodclub
                    Parc Technologique de Lyon
                  </p>
                  <p>69800 St Priest FRANCE</p>
                </dd>
              </div>
              <div className="mt-6">
                <dt className="sr-only">Phone number</dt>
                <dd className="flex">
                  <PhoneIcon className="flex-shrink-0 h-6 w-6 text-gray-400" aria-hidden="true" />
                  <span className="ml-3">06 63 86 19 84</span>
                </dd>
              </div>
              <div className="mt-3">
                <dt className="sr-only">Email</dt>
                <dd className="flex">
                  <MailIcon className="flex-shrink-0 h-6 w-6 text-gray-400" aria-hidden="true" />
                  <span className="ml-3">contact<AtSymbolIcon className="h-5 inline" />koudepouce.fr</span>
                </dd>
              </div>
            </dl>
          </div>
        </div>
        <div className="bg-white py-16 px-4 sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12">
          <div className="max-w-lg mx-auto lg:max-w-none">
            <form action="https://formulaire.koudepouce.fr/koudepouce/contact/" method="POST" className="gap-y-6">
              <div className="pb-4">
                <label htmlFor="nom" className="sr-only">
                  Nom
                </label>
                <input
                  type="text"
                  name="nom"
                  id="nom"
                  autoComplete="name"
                  className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-orange-500 focus:border-orange-500 border-gray-300 rounded-md"
                  placeholder="Nom"
                  required
                />
              </div>
              <div className="pb-4">
                <label htmlFor="Prénom" className="sr-only">
                  Prénom
                </label>
                <input
                  type="text"
                  name="prenom"
                  id="prenom"
                  autoComplete="name"
                  className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-orange-500 focus:border-orange-500 border-gray-300 rounded-md"
                  placeholder="Prénom"
                  required
                />
              </div>
              <div className="pb-4">
                <label htmlFor="cp" className="sr-only">
                  Code postal
                </label>
                <input
                  type="number"
                  name="cp"
                  id="cp"
                  className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-orange-500 focus:border-orange-500 border-gray-300 rounded-md"
                  placeholder="Code postal"
                  required
                />
              </div>
              <div className="pb-4">
                <label htmlFor="telephone" className="sr-only">
                  Téléphone
                </label>
                <input
                  type="number"
                  name="telephone"
                  id="telephone"
                  autoComplete="tel"
                  className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-orange-500 focus:border-orange-500 border-gray-300 rounded-md"
                  placeholder="Téléphone"
                  required
                />
              </div>
              <div className="pb-4">
                <label htmlFor="email" className="sr-only">
                  Email
                </label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-orange-500 focus:border-orange-500 border-gray-300 rounded-md"
                  placeholder="Email"
                  required
                />
              </div>
              <div className="pb-4">
                <label htmlFor="message" className="sr-only">
                  Votre besoin
                </label>
                <textarea
                  id="message"
                  name="message"
                  rows={4}
                  className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-orange-500 focus:border-orange-500 border border-gray-300 rounded-md"
                  placeholder="Votre besoin"
                  defaultValue={''}
                  required
                />
              </div>
              <div className="pb-4">
                <div className="mt-1">
                  <input className="jCaptcha py-3 px-4 block w-full shadow-sm text-gray-900 focus:ring-orange-500 focus:border-orange-500 border-gray-300 rounded-md" type="text" placeholder="Résultat" />
                </div>
              </div>
              <div className="pb-4">
                <input
                  type="checkbox"
                  id="mise_en_relation"
                  name="mise_en_relation"
                  className="inline mr-2 shadow-sm py-1 px-3 placeholder-gray-500 focus:ring-orange-500 focus:border-orange-500 border-gray-300 rounded-md"
                  required
                />J’accepte d’entrer en relation avec Koudepouce et ses partenaires
              </div>
              <div className="pb-4">
                <input
                  type="checkbox"
                  id="cgu"
                  name="cgu"
                  className="inline mr-2 shadow-sm py-1 px-3 placeholder-gray-500 focus:ring-orange-500 focus:border-orange-500 border-gray-300 rounded-md"
                  required
                />J'accepte les <a href="/legal/cgu/" className="text-orange-500" target="_blank" rel="noreferrer">Conditions Générales d'Utilisation</a>
              </div>

              <div className="hidden">
                <label htmlFor="tellepro" className="sr-only">
                  Téllépro
                </label>
                <input
                  type="text"
                  name="tellepro"
                  id="tellepro"
                  className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-sky-500 focus:border-sky-500 border-gray-300 rounded-md"
                  placeholder="Téllépro"
                />
              </div>

              <div className="pb-4">
                <button
                  type="submit"
                  className="inline-flex justify-center py-3 px-6 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-orange-600 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
                >
                  Valider
                </button>
              </div>
            </form>
            <Script src="/jCaptcha.js" />
            <p className="mt-3 text-xs leading-6 text-gray-400">
              Les informations recueillies sur ce formulaire sont enregistrées dans un fichier informatisé par Koudepouce SAS visant à traiter votre demande. Elles sont conservées pendant au moins 3 ans et sont destinées au service marketing et au service commercial de Koudepouce. Nos mentions légales sont accessibles ici. Conformément à la loi « informatique et libertés », vous pouvez exercer votre droit d’accès aux données vous concernant et les faire rectifier en contactant : Koudepouce, Service de la protection des données, 97 Allée Alexandre Borodine, 69800 Saint-Priest. ou par e-mail sur contact<AtSymbolIcon className="h-5 inline" />koudepouce.fr en précisant dans l’objet du courrier « Droit des personnes » et en joignant la copie de votre justificatif d’identité.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
